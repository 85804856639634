var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Type } from 'class-transformer';
import { D2CRefundPolicyDateRange } from './d2c-refund-policy-date-range.dto';
import { D2CRefundPolicyRuleDto } from './d2c-refund-policy-rule.dto';
import { BaseNumberIdDataDto } from '../../common';
export class D2CRefundPolicyDto extends BaseNumberIdDataDto {
    constructor(dto) {
        super(dto);
        this.name = dto.name;
        this.dateRanges = dto.dateRanges;
        this.rules = dto.rules;
        this.priority = dto.priority;
    }
}
__decorate([
    Type(() => D2CRefundPolicyDateRange),
    __metadata("design:type", Array)
], D2CRefundPolicyDto.prototype, "dateRanges", void 0);
__decorate([
    Type(() => D2CRefundPolicyRuleDto),
    __metadata("design:type", Array)
], D2CRefundPolicyDto.prototype, "rules", void 0);
