export var D2CErrorCode;
(function (D2CErrorCode) {
    // 400
    D2CErrorCode["EXCEED_MAX_RESERVATION_DURATION"] = "EXCEED_MAX_RESERVATION_DURATION";
    D2CErrorCode["MIN_LEAD_TIME_NOT_MET"] = "MIN_LEAD_TIME_NOT_MET";
    D2CErrorCode["EXCEEDED_PICK_UP_TIME_DEADLINE"] = "EXCEEDED_PICK_UP_TIME_DEADLINE";
    D2CErrorCode["UNAVAILABLE_OPERATION_TIME"] = "UNAVAILABLE_OPERATION_TIME";
    D2CErrorCode["PASSWORD_NOT_STRONG_ENOUGH"] = "PASSWORD_NOT_STRONG_ENOUGH";
    D2CErrorCode["PASSWORD_NOT_CORRECT"] = "PASSWORD_NOT_CORRECT";
    D2CErrorCode["USER_NOT_FOUND"] = "USER_NOT_FOUND";
    D2CErrorCode["DISCOUNT_CODE_NOT_FOUND"] = "DISCOUNT_CODE_NOT_FOUND";
    D2CErrorCode["DISCOUNT_CODE_NOT_VALID"] = "DISCOUNT_CODE_NOT_VALID";
    D2CErrorCode["DISCOUNT_CODE_MAX_AMOUNT_EXCEEDED"] = "DISCOUNT_CODE_MAX_AMOUNT_EXCEEDED";
    D2CErrorCode["DISCOUNT_CODE_MIN_AMOUNT_NOT_MET"] = "DISCOUNT_CODE_MIN_AMOUNT_NOT_MET";
    D2CErrorCode["DISCOUNT_CODE_INVALID_TYPE"] = "DISCOUNT_CODE_INVALID_TYPE";
    D2CErrorCode["DISCOUNT_CODE_INVALID_PERCENT_RANGE"] = "DISCOUNT_CODE_INVALID_PERCENT_RANGE";
    D2CErrorCode["DISCOUNT_CODE_NOT_STARTED"] = "DISCOUNT_CODE_NOT_STARTED";
    D2CErrorCode["DISCOUNT_CODE_EXPIRED"] = "DISCOUNT_CODE_EXPIRED";
    D2CErrorCode["USER_DISCOUNT_MAX_AMOUNT_EXCEEDED"] = "USER_DISCOUNT_MAX_AMOUNT_EXCEEDED";
    D2CErrorCode["USER_DISCOUNT_MIN_AMOUNT_NOT_MET"] = "USER_DISCOUNT_MIN_AMOUNT_NOT_MET";
    D2CErrorCode["SURE_DISCOUNT_MAX_AMOUNT_EXCEEDED"] = "SURE_DISCOUNT_MAX_AMOUNT_EXCEEDED";
    D2CErrorCode["SURE_DISCOUNT_MIN_AMOUNT_NOT_MET"] = "SURE_DISCOUNT_MIN_AMOUNT_NOT_MET";
    D2CErrorCode["D2C_USER_DISCOUNT_POLICY_NOT_FOUND"] = "D2C_USER_DISCOUNT_POLICY_NOT_FOUND";
    D2CErrorCode["PRICE_NOT_BE_ZERO"] = "PRICE_NOT_BE_ZERO";
    D2CErrorCode["PRICE_NOT_BE_NEGATIVE"] = "PRICE_NOT_BE_NEGATIVE";
    D2CErrorCode["NO_AVAILABLE_VEHICLE"] = "NO_AVAILABLE_VEHICLE";
    D2CErrorCode["VEHICLE_MODEL_NOT_FOUND"] = "VEHICLE_MODEL_NOT_FOUND";
    D2CErrorCode["D2C_USER_DISCOUNT_NOT_VALID"] = "D2C_USER_DISCOUNT_NOT_VALID";
    D2CErrorCode["SUPER_INSURANCE_NOT_ALLOWED_ERROR"] = "SUPER_INSURANCE_NOT_ALLOWED_ERROR";
    D2CErrorCode["RESERVATION_ALREADY_EXISTS"] = "RESERVATION_ALREADY_EXISTS";
    D2CErrorCode["RENTAL_PERIOD_LESS_THAN_ONE_DAY"] = "RENTAL_PERIOD_LESS_THAN_ONE_DAY";
    D2CErrorCode["RESERVATION_INFO_NOT_MATCH"] = "RESERVATION_INFO_NOT_MATCH";
    D2CErrorCode["SURE_USER_NOT_FOUND"] = "SURE_USER_NOT_FOUND";
    D2CErrorCode["SURE_DISCOUNT_POLICY_NOT_STARTED"] = "SURE_DISCOUNT_POLICY_NOT_STARTED";
    D2CErrorCode["SURE_DISCOUNT_POLICY_EXPIRED"] = "SURE_DISCOUNT_POLICY_EXPIRED";
    // 401
    D2CErrorCode["UNAUTHORIZED"] = "UNAUTHORIZED";
    D2CErrorCode["TOKEN_EXPIRED"] = "TOKEN_EXPIRED";
    // 403
    D2CErrorCode["UNAUTHORIZED_COMPANY_ACCESS"] = "UNAUTHORIZED_COMPANY_ACCESS";
    D2CErrorCode["UNAUTHORIZED_USER_ACCESS"] = "UNAUTHORIZED_USER_ACCESS";
    // 404
    D2CErrorCode["ERP_RESERVATION_NOT_FOUND"] = "ERP_RESERVATION_NOT_FOUND";
    // 409
    D2CErrorCode["USER_ALREADY_EXISTS"] = "USER_ALREADY_EXISTS";
    D2CErrorCode["PHONE_NUMBER_ALREADY_EXISTS"] = "PHONE_NUMBER_ALREADY_EXISTS";
    D2CErrorCode["DISCOUNT_CODE_ALREADY_EXISTS"] = "DISCOUNT_CODE_ALREADY_EXISTS";
    D2CErrorCode["D2C_USER_DISCOUNT_POLICY_ALREADY_EXISTS"] = "D2C_USER_DISCOUNT_POLICY_ALREADY_EXISTS";
    D2CErrorCode["DISCOUNT_CODE_ALREADY_APPLIED"] = "DISCOUNT_CODE_ALREADY_APPLIED";
    D2CErrorCode["USER_ALREADY_SOCIAL_REGISTERED"] = "USER_ALREADY_SOCIAL_REGISTERED";
    D2CErrorCode["RESERVATION_ALREADY_CANCELED"] = "RESERVATION_ALREADY_CANCELED";
    D2CErrorCode["PAYMENT_ALREADY_CANCELED"] = "PAYMENT_ALREADY_CANCELED";
    // 412
    D2CErrorCode["SOCIAL_LOGIN_CONFIG_NOT_FOUND"] = "SOCIAL_LOGIN_CONFIG_NOT_FOUND";
    // 422
    D2CErrorCode["SURE_DISCOUNT_POLICY_INVALID"] = "SURE_DISCOUNT_POLICY_INVALID";
    // 500
    D2CErrorCode["INVALID_D2C_COMPANY_SETTING"] = "INVALID_D2C_COMPANY_SETTING";
    D2CErrorCode["INTERNAL_SERVER_ERROR"] = "INTERNAL_SERVER_ERROR";
    D2CErrorCode["TOSS_SECRET_KEY_NOT_FOUND"] = "TOSS_SECRET_KEY_NOT_FOUND";
    D2CErrorCode["PAYMENT_NOT_FOUND"] = "PAYMENT_NOT_FOUND";
    D2CErrorCode["TOSS_PAYMENT_CONFIRM_FAILED"] = "TOSS_PAYMENT_CONFIRM_FAILED";
    D2CErrorCode["RESERVATION_FAILED"] = "RESERVATION_FAILED";
    D2CErrorCode["PAYMENT_AMOUNT_NOT_MATCH"] = "PAYMENT_AMOUNT_NOT_MATCH";
    D2CErrorCode["D2C_RESERVATION_NOT_FOUND"] = "D2C_RESERVATION_NOT_FOUND";
    D2CErrorCode["SURE_DISCOUNT_POLICY_START_DATE_NOT_FOUND"] = "SURE_DISCOUNT_POLICY_START_DATE_NOT_FOUND";
})(D2CErrorCode || (D2CErrorCode = {}));
export var ErpErrorCode;
(function (ErpErrorCode) {
    // 400
    ErpErrorCode["DISCOUNT_CODE_ALREADY_EXISTS"] = "DISCOUNT_CODE_ALREADY_EXISTS";
})(ErpErrorCode || (ErpErrorCode = {}));
