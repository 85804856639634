export class D2CTempDiscountDto {
    constructor(initializer) {
        this.category = initializer.category;
        this.discountCode = initializer.discountCode;
        this.userDiscountPolicy = initializer.userDiscountPolicy;
        this.sureDiscountPolicy = initializer.sureDiscountPolicy;
        this.discountType = initializer.discountType;
        this.discountValue = initializer.discountValue;
        this.discountAmount = initializer.discountAmount;
    }
}
