var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Type } from 'class-transformer';
import { BaseStringIdDataDto } from '../../../classes/common';
import { D2CPaymentDto } from '../../../classes/d2c-payment/response/d2c-payment.dto';
import { D2CPreReservationDiscountDto } from '../../../classes/d2c-pre-reservation-discount/response/d2c-pre-reservation-discount.dto';
import { D2CTempDiscountDto } from '../../../classes/d2c-pre-reservation-discount/response/d2c-temp-discount.dto';
import { VehicleModelWithoutRelationsDto } from '../../../classes/vehicle-model/response/vehicle-model-without-relations.dto';
import { ReservationWithoutOneToManyRelationsDto } from './reservation-without-relations.dto';
export class PreReservationDto extends BaseStringIdDataDto {
    constructor(initializer) {
        super(initializer);
        this.companyId = initializer.companyId;
        this.companyName = initializer.companyName;
        this.reservationId = initializer.reservationId;
        this.vehicleModelId = initializer.vehicleModelId;
        this.d2cPaymentId = initializer.d2cPaymentId;
        this.d2cUserId = initializer.d2cUserId;
        this.insuranceName = initializer.insuranceName;
        this.customerName = initializer.customerName;
        this.customerPhoneNumber = initializer.customerPhoneNumber;
        this.customerEmail = initializer.customerEmail;
        this.pickUpAt = initializer.pickUpAt;
        this.dropOffAt = initializer.dropOffAt;
        this.price = initializer.price;
        this.listPrice = initializer.listPrice;
        this.insurancePrice = initializer.insurancePrice;
        this.fullCoverageInsurancePrice = initializer.fullCoverageInsurancePrice;
        this.discount = initializer.discount;
        this.isFullCoverage = initializer.isFullCoverage;
        this.reservation = initializer.reservation;
        this.vehicleModel = initializer.vehicleModel;
        this.d2cPayment = initializer.d2cPayment;
        this.preReservationDiscounts = initializer.preReservationDiscounts;
        this.tempDiscounts = initializer.tempDiscounts;
    }
}
__decorate([
    Type(() => Date),
    __metadata("design:type", Date)
], PreReservationDto.prototype, "pickUpAt", void 0);
__decorate([
    Type(() => Date),
    __metadata("design:type", Date)
], PreReservationDto.prototype, "dropOffAt", void 0);
__decorate([
    Type(() => ReservationWithoutOneToManyRelationsDto),
    __metadata("design:type", Object)
], PreReservationDto.prototype, "reservation", void 0);
__decorate([
    Type(() => VehicleModelWithoutRelationsDto),
    __metadata("design:type", VehicleModelWithoutRelationsDto)
], PreReservationDto.prototype, "vehicleModel", void 0);
__decorate([
    Type(() => D2CPaymentDto),
    __metadata("design:type", Object)
], PreReservationDto.prototype, "d2cPayment", void 0);
__decorate([
    Type(() => D2CPreReservationDiscountDto),
    __metadata("design:type", Array)
], PreReservationDto.prototype, "preReservationDiscounts", void 0);
__decorate([
    Type(() => D2CTempDiscountDto),
    __metadata("design:type", Array)
], PreReservationDto.prototype, "tempDiscounts", void 0);
