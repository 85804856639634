import { datadogRum } from '@datadog/browser-rum';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { init } from '@sentry/electron/renderer';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { locale } from 'dayjs';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { HashRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import App from './app/App';
import theme from './app/theme';
import { persistor, store } from './redux/reducers/store';

locale('ko');

init({
  dsn: 'https://f0eefe64efebc7bb0bedf23bcc7dbe80@o4505696477839360.ingest.sentry.io/4506658912796672',
});

const serverHost = import.meta.env.RENDERER_VITE_API_BASE_URL;
const wsHost = import.meta.env.RENDERER_VITE_WEB_SOCKET_HOST;

datadogRum.init({
  applicationId: 'cd43bf3c-415e-4b0a-a59c-a81da875fd4d',
  clientToken: 'pubfaa269b78040d9a8508d0b7bc91c8365',
  // `site` refers to the Datadog site parameter of your organization
  // see https://docs.datadoghq.com/getting_started/site/
  site: 'us5.datadoghq.com',
  service: 'kiosk',
  env: 'prod',
  // Specify a version number to identify the deployed version of your application in Datadog
  // version: '1.0.0',
  sessionSampleRate: 100,
  sessionReplaySampleRate: 100,
  defaultPrivacyLevel: 'allow',
  // trace
  allowedTracingUrls: [serverHost, wsHost],
  trackResources: true,
  trackLongTasks: true,
  trackUserInteractions: true,
  traceSampleRate: 100,
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false, // 다른 window 갔다가 focus했을 때 자동으로 refetch 되는 옵션
      retry: 0,
    },
  },
});

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <HashRouter>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <App />
            </ThemeProvider>
          </HashRouter>
        </PersistGate>
      </Provider>
    </QueryClientProvider>
  </React.StrictMode>,
);
